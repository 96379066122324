import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { InputText } from "primereact/inputtext";
import appUrl from '../../../../constants/appUrl';
import axios from 'axios';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddEditRequest = ({ onHide, editable, setRes, setDialog, rowData }) => {
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email format').required('Required'),
    phoneNumber: Yup.string().required('Required').max(15, 'Mobile Number must be at most 15 characters'),
    organizationName: Yup.string().required('Required'),
    organizationAddress: Yup.string().required('Required'),
    organizationEmail: Yup.string().email('Invalid email format').required('Required'),
    organizationPhoneNumber: Yup.string().required('Required').max(15, 'Mobile Number must be at most 15 characters')
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: '',
      address: '',
      email: '',
      phoneNumber: '',
      organizationName: '',
      organizationAddress: '',
      organizationEmail: '',
      organizationPhoneNumber: ''
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        const payload = {
          name: data.name,
          email: data.email,
          phoneNumber: data.phoneNumber,
          address: data.address,
          organizationName: data.organizationName,
          organizationAddress: data.organizationAddress,
          organizationEmail: data.organizationEmail,
          organizationPhoneNumber: data.organizationPhoneNumber
        };

        let response;

        if (editable) {
          data.id = rowData.id;
          response = await axios.put(`${appUrl.baseUrl}/api/Organizations/update`, data, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          });
        } else {
          response = await axios.post(`${appUrl.baseUrl}/api/RegistrationRequests/add`, payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          });
        }

        if (response.data && response.data.responseCode === 200) {
          setRes(response);
          toast.success(editable ? "Updated Successfully" : "Added Successfully");
          setDialog(false);
        } else {
          if (response.data && response.data.responseDescription) {
            toast.error(response.data.responseDescription);
          } else {
            toast.error("Failed to perform operation");
          }
        }
      } catch (error) {
        console.error("Error occurred:", error);
        toast.error("Failed to perform operation");
      } finally {
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (editable) {
      formik.setFieldValue('name', rowData?.name);
      formik.setFieldValue('address', rowData?.address);
      formik.setFieldValue('email', rowData?.email);
      formik.setFieldValue('phoneNumber', rowData?.phoneNumber);
      formik.setFieldValue('organizationName', rowData?.organizationName);
      formik.setFieldValue('organizationAddress', rowData?.organizationAddress);
      formik.setFieldValue('organizationEmail', rowData?.organizationEmail);
      formik.setFieldValue('organizationPhoneNumber', rowData?.organizationPhoneNumber);
    }
  }, [editable, rowData]);

  return (
    <>  
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-6">
            <label htmlFor="name" style={{ fontWeight: "bold" }}>Name <span className='Staric-Custom'> *</span></label>
            <InputText
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? <div className='error'>{formik.errors.name}</div> : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="address" style={{ fontWeight: "bold" }}>Address <span className='Staric-Custom'> *</span></label>
            <InputText
              id="address"
              maxLength={99}
              name="address"
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
            {formik.touched.address && formik.errors.address ? <div className='error'>{formik.errors.address}</div> : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="email" style={{ fontWeight: "bold" }}>Email <span className='Staric-Custom'> *</span></label>
            <InputText
              id="email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? <div className='error'>{formik.errors.email}</div> : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="phoneNumber" style={{ fontWeight: "bold" }}>Mobile Number <span className='Staric-Custom'> *</span></label>
            <InputText
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? <div className='error'>{formik.errors.phoneNumber}</div> : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="organizationName" style={{ fontWeight: "bold" }}>Organization Name <span className='Staric-Custom'> *</span></label>
            <InputText
              id="organizationName"
              name="organizationName"
              type="text"
              value={formik.values.organizationName}
              onChange={formik.handleChange}
            />
            {formik.touched.organizationName && formik.errors.organizationName ? <div className='error'>{formik.errors.organizationName}</div> : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="organizationAddress" style={{ fontWeight: "bold" }}>Organization Address <span className='Staric-Custom'> *</span></label>
            <InputText
              id="organizationAddress"
              name="organizationAddress"
              type="text"
              value={formik.values.organizationAddress}
              onChange={formik.handleChange}
            />
            {formik.touched.organizationAddress && formik.errors.organizationAddress ? <div className='error'>{formik.errors.organizationAddress}</div> : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="organizationEmail" style={{ fontWeight: "bold" }}>Organization Email <span className='Staric-Custom'> *</span></label>
            <InputText
              id="organizationEmail"
              name="organizationEmail"
              type="text"
              value={formik.values.organizationEmail}
              onChange={formik.handleChange}
            />
            {formik.touched.organizationEmail && formik.errors.organizationEmail ? <div className='error'>{formik.errors.organizationEmail}</div> : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="organizationPhoneNumber" style={{ fontWeight: "bold" }}>Organization Mobile Number <span className='Staric-Custom'> *</span></label>
            <InputText
              id="organizationPhoneNumber"
              name="organizationPhoneNumber"
              type="text"
              value={formik.values.organizationPhoneNumber}
              onChange={formik.handleChange}
            />
            {formik.touched.organizationPhoneNumber && formik.errors.organizationPhoneNumber ? <div className='error'>{formik.errors.organizationPhoneNumber}</div> : null}
          </div>

          <div className="field md:col-12">
            <Button label={loading ? 'Submitting...' : editable ? 'Update' : 'Submit'} type="submit" className="p-button-success" disabled={loading} />
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditRequest;
