import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddEditAdminControl from "../components/add_edit_adminControl";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import { confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Loader from "../../../components/loader";
import editIcon from "../../../../../assets/icons/bx_edit.png";
import deleteIcon from "../../../../../assets/icons/trash.png";
import UpdateAdminStatus from "../components/edit_registration-requests_status";
import { Tag } from "primereact/tag";
import {
  formatDate,
  renderTextWithTooltip,
  formatTo12Hour,
  emailsRenderTextWithTooltip,
} from "../../../components/helper";
import fetchWith401Handling from "../../../../services/fetchWith401Handling";
import * as XLSX from "xlsx";

const AdminControl = () => {
  const [rowselect, setRowselect] = useState(null);
  const [dialog, setDialog] = useState();
  const [editDialog, setEditDialog] = useState();
  const [gateList, setGateList] = useState();
  const [res, setRes] = useState(false);
  const [editableTwo, setEditableTwo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const dt = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const GetGates = async () => {
    try {
      setLoading(true);
      const response = await fetchWith401Handling(
        `${appUrl.baseUrl}/api/AdminUser/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setGateList(data?.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const onHide = () => {
    setDialog(false);
  };
  const onHideTwo = () => {
    setEditDialog(false);
  };
  const confirm = (rowData) => {
    confirmDialog({
      header: (
        <div className="custom-header mb-3 mt-3">
          {/* <i className="pi pi-trash custom-btn-delete"></i> */}
        </div>
      ),
      message: (
        <strong>Are you sure you want to delete this Organization?</strong>
      ),
      accept: () => accept(rowData),
      reject: () => reject(rowData),
      acceptClassName: "custom-btn-red",
      rejectClassName: "custom-white-red",
      className: "center-buttons no-close-button",
    });
  };

  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setDialog(true);
  };

  const editActionTwo = (rowData) => {
    setEditableTwo(true);
    setRowselect(rowData);
    setEditDialog(true);
  };
  const accept = async (rowData) => {
    try {
      const response = await axios.delete(
        `${appUrl.baseUrl}/api/Organizations/delete/${rowData?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRes(response.data);
      toast.success("Deleted Successfully");
    } catch (error) {}
  };
  const reject = (rowData) => {
    return;
  };

  const updateStatus = async (id, isActive) => {
    setLoading(true);

    try {
      const response = await axios.put(
        `${appUrl.baseUrl}/api/AdminUser/update/status`,
        {
          id,
          isActive,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRes(response.data);
      toast.success(
        `Organization Admin  is now ${isActive ? "Active" : "Non-active"}`
      );
    } catch (error) {
      toast.error(`Failed to update status.`);
    } finally {
      setLoading(false);
    }
  };

  const actionTemplate = (rowData) => (
    <div className="flex align-items-center">
      <div
        className={`toggle-switch ${rowData.isActive ? "active" : "inactive"}`}
        onClick={() => updateStatus(rowData.id, !rowData.isActive)}
      >
        <div
          className={`toggle-knob ${rowData.isActive ? "active" : "inactive"}`}
        />
      </div>
      <Button
        className="custom-btn-edit"
        onClick={() => {
          editAction(rowData);
        }}
      >
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>

      {/* <Button
        className="custom-btn-delete"
        onClick={() => {
          editActionTwo(rowData);
        }}
      >
        <img
          src={deleteIcon}
          alt="Delete"
          style={{ width: "20px", height: "20px" }}
        />
      </Button> */}
    </div>
  );

  const exportCSV = () => {
    const exportData = gateList.map((item) => ({
      "User Name": item.name,
      "Mobile Number": item.phoneNumber,
      Role: item.role,
      Address: item.address,
      "Modified Date": formatTo12Hour(item.modifiedDate),
      Status: item.isActive ? "Active" : "Non-active",
    }));

    // Create a new workbook and a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Define column widths (all set to 120 pixels)
    worksheet["!cols"] = [
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
    ];

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Admin Data");

    // Write the workbook to file
    XLSX.writeFile(workbook, "admin_data.xlsx");
  };

  useEffect(() => {
    GetGates();
  }, [res]);

  const formattedStatus = (rowData) => {
    return (
      <div>
        <Tag
          className={rowData.isActive ? "p-tag-success" : "p-tag-danger"}
          style={{
            width: "100%",
            minWidth: "80px",
            maxWidth: "80px",
            whiteSpace: "nowrap",
          }}
        >
          {rowData.isActive ? "Active" : "Non-active"}
        </Tag>
      </div>
    );
  };
  return (
    <>
      <ToastContainer />
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={editable ? "Edit Admin" : "Add Admin"}
        // header="Add Organizations"
        style={{ width: "50vw" }}
      >
        <AddEditAdminControl
          dialog={dialog}
          editable={editable}
          rowData={rowselect}
          setDialog={setDialog}
          onHide={onHide}
          setRes={setRes}
        />
      </Dialog>

      <Dialog
        visible={editDialog}
        onHide={onHideTwo}
        header={"Update User Status"}
        style={{ width: "50vw" }}
      >
        <UpdateAdminStatus
          dialog={editDialog}
          editable={editableTwo}
          rowData={rowselect}
          setDialog={setEditDialog}
          onHide={onHideTwo}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive">
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          />

          <Button
            className="custom-btn  ml-3"
            label="Add New"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setDialog(true);
            }}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          value={gateList}
          scrollable
          scrollHeight="450px"
          filters={filters}
          paginator
          rows={10}
          ref={dt}
          globalFilterFields={["number"]}
        >
          <Column
            field="accessControls[0].organization.name"
            header="Organization Name"
            body={(rowData) =>
              rowData.accessControls[0]?.organization?.name || "N/A"
            }
          />
          <Column
            field="name"
            header="User Name"
            body={(rowData) => renderTextWithTooltip(rowData, "name", "bottom")}
          />
          <Column field="phoneNumber" header="Mobile Number" />
          <Column field="role" header="Role" />
          <Column
            field="address"
            header="Address"
            body={(rowData) =>
              renderTextWithTooltip(rowData, "address", "bottom")
            }
          />
          <Column
            field="modifiedDate"
            header="Date"
            body={(rowData) => formatDate(rowData.modifiedDate)}
          />
          <Column
            header="Profile Image"
            body={(rowData) => (
              <img
                src={`${appUrl.baseUrl}${rowData.profileImageUrl}`}
                alt="Profile"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            )}
          />
          <Column field="Status" header="Stauts" body={formattedStatus} />
          <Column
            field=""
            header="Action"
            body={actionTemplate}
            style={{ minWidth: "12rem" }}
          />
        </DataTable>
      </div>
    </>
  );
};

export default AdminControl;
