import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";

const AddEditGroups = ({ onHide, editable, setRes, setDialog, rowData }) => {
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Required")
      .max(30, "Name must be 30 characters"),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        let response;
        if (editable) {
          data.id = rowData.id;
          const updateData = {
            id: rowData.id,
            name: data.name,
          };
          response = await axios.put(
            `${appUrl.baseUrl}/api/Groups/update`,
            updateData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        } else {
          response = await axios.post(
            `${appUrl.baseUrl}/api/Groups/add`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        }

        if (response.data && response.data.responseCode === 200) {
          // Success case
          setRes(response);
          toast.success(
            editable ? "Group Updated Successfully" : "Group Added Successfully"
          );
          setDialog(false);
        } else {
          const errorMessage = response.data.responseDescription || "Failed to perform operation";
          toast.error(errorMessage);
        }
      } catch (error) {
        console.error("Error occurred:", error);
        const errorMessage = error.response?.data?.responseDescription || "Failed to perform operation";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (editable) {
      formik.setFieldValue("name", rowData?.name);
    }
  }, [editable, rowData]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="p-fluid formgrid grid">
        <div className="field  md:col-6">
          <label htmlFor="" style={{ fontWeight: "bold" }}>
            Name <span className="Staric-Custom"> *</span>
          </label>
          <InputText
            maxLength={30}
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={(e) => {
              const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, "");
              formik.setFieldValue("name", sanitizedValue);
            }}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="error">{formik.errors.name}</div>
          ) : null}
        </div>

        <div className="col-12 md:col-8"></div>
        <div className="col-12 md:col-2">
          <div className="flex justify-content-center">
            <Button
              className="custom-white w100 "
              label="Cancel"
              type="button"
              onClick={onHide}
            />
          </div>
        </div>
        <div className="col-12 md:col-2">
          <div className="flex justify-content-center">
            <Button
              className="custom-btn w100"
              label={editable ? "Update" : "Add "}
              type="submit"
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddEditGroups;
