import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";

const AddEditAdminControl = ({
  onHide,
  editable,
  setRes,
  setDialog,
  rowData,
}) => {
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [branches, setBranches] = useState([]);
  const [profileImageBase64, setProfileImageBase64] = useState("");

  // Convert file to base64
  const handleImageUpload = (event) => {
    const file = event.files[0];
    setFileName(file.name);
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result.split(",")[1]; // Removing the base64 prefix
      setProfileImageBase64(base64Image); // Update local state
      formik.setFieldValue("profileImageBase64", base64Image); // Set Formik field
    };

    if (file) {
      reader.readAsDataURL(file); // Convert file to base64
    }
  };
  const validationSchema = Yup.object({
    organizationId: Yup.number().required("Required"),

    name: Yup.string()
      .required("Required")
      .matches(
        /^[A-Za-z\s]+$/,
        "Name must contain only alphabetic characters and spaces"
      )
      .max(30, "Name must be at most 30 characters long"),

    cnicNumber: Yup.string()
      .required("Required")
      .matches(/^\d{13}$/, "CNIC number must be exactly 13 digits"),

    address: Yup.string().required("Required"),

    email: Yup.string().email("Invalid email format").required("Required"),

    phoneNumber: Yup.string()
      .required("Mobile Number is required")
      .matches(
        /^\+92\d{10}$/,
        "Mobile Number must start with +92 and have 13 digits long"
      )
      .min(13, "Mobile Number must be exactly 13 digits long")
      .max(13, "Mobile Number must be exactly 13 digits long"),

    editable: Yup.boolean().default(false),
   
    profileImageBase64: Yup.string().required("Profile image is required"),
    password: editable
    ? Yup.string().notRequired()
    : Yup.string()
    .required("Password is required")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}/,
      "Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol."
    ),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      organizationId: "",
      branchId: null,
      name: "",
      cnicNumber: "",
      address: "",
      email: "",
      phoneNumber: "",
      password: "",
      profileImageBase64: "",
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        const payload = {
          organizationId: data.organizationId,
          branchId: data.branchId,
          name: data.name,
          cnicNumber: data.cnicNumber,
          address: data.address,
          email: data.email,
          phoneNumber: data.phoneNumber,
          profileImageBase64: profileImageBase64,
          // password: editable ? undefined : data.password,
          password: data.password,
        };

        let response;
        if (editable) {
          payload.id = rowData.id; // Include ID for update
          response = await axios.put(
            `${appUrl.baseUrl}/api/AdminUser/update`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        } else {
          response = await axios.post(
            `${appUrl.baseUrl}/api/AdminUser/add`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        }

        if (response && response.data) {
          const { responseCode, responseDescription } = response.data;

          if (responseCode === 200) {
            setRes(response);
            toast.success(
              editable ? "Updated Successfully" : "Added Successfully"
            );
            setDialog(false);
          } else if (responseCode === 409) {
            toast.error(responseDescription || "A conflict occurred");
          } else {
            toast.error(responseDescription || "Failed to perform operation");
          }
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        if (error.response) {
          const { responseDescription } = error.response.data || {};
          console.error("API Error Response:", error.response.data);
          toast.error(responseDescription || "Failed to perform operation");
        } else {
          console.error("Error occurred:", error);
          toast.error("Failed to perform operation");
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const getOrganizations = async (rowData) => {
    try {
      const response = await axios.get(
        `${appUrl.baseUrl}/api/Organizations/list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setOrganizations(response.data?.data);
    } catch (error) {}
  };
  const getBranches = async (rowData) => {
    try {
      const response = await axios.get(
        `${appUrl.baseUrl}/api/Organizations/branches/list?${formik.values.organizationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setBranches(response.data?.data);
      // toast.success("Deleted Successfully");
    } catch (error) {}
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  useEffect(() => {
    if (
      formik.values.organizationId != null ||
      formik.values.organizationId != ""
    ) {
      getBranches();
    }
  }, [formik.values.organizationId]);

  const handleInitialImage = async (imageUrl) => {
    try {
      // Fetch the image data from the URL
      const response = await axios.get(imageUrl, {
        responseType: "arraybuffer",
      });
      // Convert the response data to Base64
      const base64 = Buffer.from(response.data, "binary").toString("base64");
      // Set the Base64 string to Formik field
      // formik.setFieldValue('profileImageBase64', `data:image/jpeg;base64,${base64}`);
      formik.setFieldValue("profileImageBase64", base64);
      const extractedFileName = imageUrl.split("/").pop(); // Get the last part of the URL as the file name
      setFileName(extractedFileName);
    } catch (error) {
      console.error("Error fetching and converting image:", error);
    }
  };

  useEffect(() => {
    if (editable) {
      // Initialize form values
      formik.setFieldValue("name", rowData?.name);
      const organizationId =
        rowData?.accessControls?.[0]?.organization?.id || "";
      formik.setFieldValue("organizationId", organizationId);
      formik.setFieldValue("cnicNumber", rowData?.cnicNumber);
      formik.setFieldValue("address", rowData?.address);
      formik.setFieldValue("email", rowData?.email);
      formik.setFieldValue("phoneNumber", rowData?.phoneNumber);

      if (rowData.profileImageUrl) {
        handleInitialImage(`${appUrl.baseUrl}${rowData.profileImageUrl}`);
      }
    }
  }, [editable, rowData]);

  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Organization <span className="Staric-Custom"> *</span>
            </label>
            <Dropdown
              value={formik.values.organizationId}
              optionLabel="name"
              name="organizationId"
              options={organizations}
              optionValue="id"
              placeholder="Select"
              filter
              onChange={formik.handleChange}
            />
            {formik.touched.organizationId && formik.errors.organizationId ? (
              <div className="error">{formik.errors.organizationId}</div>
            ) : null}
          </div>
          {/* <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Branches
            </label>
            <Dropdown
              value={formik.values.branchId}
              optionLabel="name"
              name="branchId"
              options={branches}
              optionValue="id"
              placeholder="Select"
              filter
              onChange={formik.handleChange}
            />
            {formik.touched.branchId && formik.errors.branchId ? (
              <div className="error">{formik.errors.branchId}</div>
            ) : null}
          </div> */}
          <div className="field  md:col-6">
            <label htmlFor="name" style={{ fontWeight: "bold" }}>
              Name <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              keyfilter={/^[a-zA-Z\s]*$/}
              maxLength={30}
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="cnicNumber" style={{ fontWeight: "bold" }}>
              CNIC Number <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              maxLength={13}
              id="cnicNumber"
              name="cnicNumber"
              type="text"
              value={formik.values.cnicNumber}
              onChange={(e) =>
                formik.setFieldValue(
                  "cnicNumber",
                  e.target.value.replace(/[^0-9]/g, "")
                )
              }
            />
            {formik.touched.cnicNumber && formik.errors.cnicNumber ? (
              <div className="error">{formik.errors.cnicNumber}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="address" style={{ fontWeight: "bold" }}>
              Address <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              maxLength={99}
              id="address"
              name="address"
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
            {formik.touched.address && formik.errors.address ? (
              <div className="error">{formik.errors.address}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="email" style={{ fontWeight: "bold" }}>
              Email <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              id="email"
              name="email"
              type="text"
              // autoComplete="new-email"
              autoComplete="off"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="phoneNumber" style={{ fontWeight: "bold" }}>
              Mobile Number <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              maxLength={13}
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              value={formik.values.phoneNumber}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(
                  /(?!^\+)[^\d]/g,
                  ""
                );

                if (sanitizedValue.length <= 13) {
                  formik.setFieldValue("phoneNumber", sanitizedValue);
                }
              }}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="error">{formik.errors.phoneNumber}</div>
            ) : null}
          </div>

          {!editable && (
            <div className="field  md:col-6 password-icon">
              <label htmlFor="password" style={{ fontWeight: "bold" }}>
                Password <span className="Staric-Custom"> *</span>
              </label>

              <Password
                // className="auth-welcome"
                placeholder="Enter password"
                type="text"
                id="password"
                name="password"
                maxLength={30}
                value={formik.values.password}
                toggleMask
                onChange={formik.handleChange}
                feedback={false}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>
          )}

          <div className="field  md:col-6">
            <label htmlFor="profileImage" style={{ fontWeight: "bold" }}>
              Upload Profile Image <span className="Staric-Custom"> *</span>
            </label>
            <FileUpload
              mode="basic"
              name="profileImage"
              accept="image/*"
              maxFileSize={1000000}
              onSelect={handleImageUpload}
              auto
            />
            {/* {fileName && <p>Selected file: {fileName}</p>} */}
            {fileName && (
              <p
                style={{
                  marginBottom: "-8px",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              >
                Selected file:
              </p>
            )}

            {formik.values.profileImageBase64 && (
              <div style={{ marginTop: "10px" }}>
                <img
                  src={`data:image/jpeg;base64,${formik.values.profileImageBase64}`}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "100px",
                    objectFit: "cover",
                    border: "1px solid #ced4da",
                    borderRadius: "12px",
                  }}
                />
              </div>
            )}
            {formik.touched.profileImageBase64 &&
            formik.errors.profileImageBase64 ? (
              <div className="error">{formik.errors.profileImageBase64}</div>
            ) : null}
          </div>

          <div className="col-12 md:col-8"></div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-white w100"
                label="Cancel"
                type="button"
                onClick={onHide}
              />
            </div>
          </div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-btn w100"
                label={editable ? "Update" : "Add"}
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditAdminControl;
