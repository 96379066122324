import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddEditOrganization = ({
  onHide,
  editable,
  setRes,
  setDialog,
  rowData,
}) => {
  const [loading, setLoading] = useState(false);
  const [imageBase64, setImageBase64] = useState("");
  const [fileName, setFileName] = useState("");
  const handleImageUpload = (event) => {
    const file = event.files[0];
    setFileName(file.name);
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result.split(",")[1]; // Removing the base64 prefix
      setImageBase64(base64Image); // Update local state
      formik.setFieldValue("logoImageBase64", base64Image); // Set Formik field
    };

    if (file) {
      reader.readAsDataURL(file); // Convert file to base64
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Required")
      .matches(
        /^[A-Za-z\s]+$/,
        "Name must contain only alphabetic characters and spaces"
      )
      .max(30, "Name must be at most 30 characters long"),
    address: Yup.string()
      .required("Address is required")
      .min(5, "Address must be at least 5 characters")
      .max(30, "Address must be at most 99 characters"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .required("Mobile Number is required")
      .matches(
        /^\+92\d{10}$/,
        "Mobile Number must start with +92 and have 13 digits long"
      )
      .min(13, "Mobile Number must be exactly 13 digits long")
      .max(13, "Mobile Number must be exactly 13 digits long"),
    logoImageBase64: Yup.string().required("Logo image is required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      // colorPalette:'',
      name: "",
      address: "",
      email: "",
      phoneNumber: "",
      logoImageBase64: "",
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        const payload = {
          name: data.name,
          logoImageBase64: imageBase64,
          // colorPalette: data.colorPalette,
          address: data.address,
          email: data.email,
          phoneNumber: data.phoneNumber,
        };
        let response;

        if (editable) {
          data.id = rowData.id;
          response = await axios.put(
            `${appUrl.baseUrl}/api/Organizations/update`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        } else {
          response = await axios.post(
            `${appUrl.baseUrl}/api/Organizations/add`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        }

        if (response.data && response.data.responseCode === 200) {
          setRes(response);
          toast.success(
            editable ? "Updated Successfully" : "Added Successfully"
          );
          setDialog(false);
        } else {
          if (response.data && response.data.responseDescription) {
            toast.error(response.data.responseDescription);
          } else {
            toast.error("Failed to perform operation");
          }
        }
      } catch (error) {
        console.error("Error occurred:", error);
        toast.error("Failed to perform operation");
      } finally {
        setLoading(false);
      }
    },
  });
  
  const handleInitialImage = async (imageUrl) => {
    try {
      // Fetch the image data from the URL
      const response = await axios.get(imageUrl, {
        responseType: "arraybuffer",
      });

      // Convert the response data to Base64
      const base64 = Buffer.from(response.data, "binary").toString("base64");

      // Set the Base64 string to Formik field without the 'data:image/jpeg;base64,' prefix
      formik.setFieldValue("logoImageBase64", base64);
      const extractedFileName = imageUrl.split("/").pop();
      setFileName(extractedFileName);
    } catch (error) {
      console.error("Error fetching and converting image:", error);
    }
  };

  useEffect(() => {
    if (editable) {
      formik.setFieldValue("name", rowData?.name);
      formik.setFieldValue("address", rowData?.address);
      formik.setFieldValue("email", rowData?.email);
      formik.setFieldValue("phoneNumber", rowData?.phoneNumber);

      if (rowData.logoUrl) {
        handleInitialImage(`${appUrl.baseUrl}${rowData.logoUrl}`);
      }
    }
  }, [editable, rowData]);

  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-6">
            <label htmlFor="name" style={{ fontWeight: "bold" }}>
              Name <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              keyfilter={/^[a-zA-Z\s]*$/}
              maxLength={30}
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="address" style={{ fontWeight: "bold" }}>
              Address <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              maxLength={99}
              id="address"
              name="address"
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
            {formik.touched.address && formik.errors.address ? (
              <div className="error">{formik.errors.address}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="email" style={{ fontWeight: "bold" }}>
              Email <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              id="email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="phoneNumber" style={{ fontWeight: "bold" }}>
              Mobile Number <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              maxLength={13}
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              value={formik.values.phoneNumber}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(
                  /(?!^\+)[^\d]/g,
                  ""
                );

                if (sanitizedValue.length <= 13) {
                  formik.setFieldValue("phoneNumber", sanitizedValue);
                }
              }}
            />

            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="error">{formik.errors.phoneNumber}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="logoImageBase64" style={{ fontWeight: "bold" }}>
              Upload Logo <span className="Staric-Custom"> *</span>
            </label>
            <FileUpload
              mode="basic"
              name="logoImageBase64"
              accept="image/*"
              maxFileSize={1000000}
              onSelect={handleImageUpload}
              auto
            />
            {fileName && (
              <p
                style={{
                  marginBottom: "-8px",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              >
                Selected file:
              </p>
            )}

            {formik.values.logoImageBase64 && (
              <div style={{ marginTop: "10px" }}>
                <img
                  src={`data:image/jpeg;base64,${formik.values.logoImageBase64}`}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "100px",
                    objectFit: "cover",
                    border: "1px solid #ced4da",
                    borderRadius: "12px",
                  }}
                />
              </div>
            )}
            {formik.touched.logoImageBase64 && formik.errors.logoImageBase64 ? (
              <div className="error">{formik.errors.logoImageBase64}</div>
            ) : null}
          </div>

          <div className="col-12 md:col-8"></div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-white w100"
                label="Cancel"
                type="button"
                onClick={onHide}
              />
            </div>
          </div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-btn w100"
                label={editable ? "Update" : "Add"}
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditOrganization;
