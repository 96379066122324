import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import logo from "./assets/logo/logo.png";
import appUrl from "./app/constants/appUrl";
import imageIcon from "../src/assets/icons/user-icon.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { ToastContainer, toast } from "react-toastify";

export const AppTopbar = (props) => {

  const history = useHistory();
  const { user } = props;

  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  const confirm = () => {
    confirmDialog({
      message: "Are you sure you want to Logout?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
      acceptClassName: "custom-bg color-white",
      rejectClassName: "custom-white",
    });
  };

  const accept = () => {
    window.localStorage.clear();
    localStorage.removeItem("login");
    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    history.push("./");
  };

  const reject = () => {
    return;
  };

  const changePasswordValidationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}/,
        "Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol."
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formikPasswordChange = useFormik({
    validationSchema: changePasswordValidationSchema,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      try {
        const payload = {
          id: user?.id,
          password: values.password,
        };
        const response = await axios.put(
          `https://qa-zsport.appinsnap.com/VirtualCard/api/Admin/update/password`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
       
        toast.success("Password updated successfully");
        setShowPasswordDialog(false);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "Password update failed due to a network error. Please try again.";
        toast.error(errorMessage);
      }
    },
  });



  return (
    <>
          <ToastContainer />

      <ConfirmDialog />

      {showPasswordDialog && (
        <Dialog
          visible={showPasswordDialog}
          header="Change Your Password"
          onHide={() => setShowPasswordDialog(false)}
          style={{ width: "350px" }}
          footer={
            <Button
              type="submit"
              label="Change Password"
              onClick={formikPasswordChange.handleSubmit}
              className="custom-btn mt-3"
            />
          }
        >
          <form
            className="p-fluid formgrid grid auth-login"
            onSubmit={formikPasswordChange.handleSubmit}
          >
            <div className="field md:col-12 mb-0">
              <label>Password</label>
              <Password
                id="password"
                name="password"
                placeholder="Enter password"
                value={formikPasswordChange.values.password}
                onChange={formikPasswordChange.handleChange}
                toggleMask
                feedback={false}
              />
              {formikPasswordChange.touched.password &&
              formikPasswordChange.errors.password ? (
                <div className="error">
                  {formikPasswordChange.errors.password}
                </div>
              ) : null}
            </div>
            <div className="field md:col-12 mb-0">
              <label>Confirm Password</label>
              <Password
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm your password"
                value={formikPasswordChange.values.confirmPassword}
                onChange={formikPasswordChange.handleChange}
                toggleMask
                feedback={false}
              />
              {formikPasswordChange.touched.confirmPassword &&
              formikPasswordChange.errors.confirmPassword ? (
                <div className="error">
                  {formikPasswordChange.errors.confirmPassword}
                </div>
              ) : null}
            </div>
          </form>
        </Dialog>
      )}

      <div className="layout-topbar">
        <div className="layout-topbar-logo">
          <img src={logo} alt="logo" />
          <span className="custom-color">Virtual Card</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="layout-topbar-ellipsis flex align-items-center">
            <button
              type="button"
              className="p-link  layout-menu-button layout-topbar-button"
              onClick={props.onToggleMenuClick}
            >
              <i className="pi pi-bars" />
            </button>
            {props.currentRouteLabel && (
              <div className="layout-topbar-heading">
                {props.currentRouteLabel}
              </div>
            )}
          </div>

          <div className="layout-topbar-user">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle d-flex align-items-center"
                style={{ gap: "10px" }}
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="profile-container">
                  <img
                    src={
                      user?.profileImageUrl
                        ? `${appUrl.baseUrl}${user.profileImageUrl}`
                        : imageIcon
                    }
                    alt="Profile"
                    className="profile-image"
                  />
                  <div className="status-dot"></div>
                </div>
                Hello,&nbsp; {user ? user?.name : "Admin"} &nbsp;
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {user?.accessControls &&
                  user.accessControls.length > 0 &&
                  user.accessControls[0].organization && (
                    <li>
                      <span style={{ padding: "15px" }}>
                        <b>Organization Details</b>
                      </span>
                      <a className="dropdown-item d-flex align-items-center">
                        <img
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                          src={
                            user.accessControls[0].organization.logoUrl
                              ? `${appUrl.baseUrl}${user.accessControls[0].organization.logoUrl}`
                              : imageIcon
                          }
                          alt="Organization Logo"
                          className="profile-image"
                        />
                        {user.accessControls[0].organization.name}
                      </a>
                    </li>
                  )}
                {user?.userName && (
                  <li>
                    <span style={{ padding: "15px" }}>
                      <b> Admin Details</b>
                    </span>
                    <a className="dropdown-item">
                      <i
                        className="pi pi-phone"
                        style={{ marginRight: "10px" }}
                      ></i>
                      {user.userName}
                    </a>
                  </li>
                )}
                {user?.email && (
                  <li>
                    <a className="dropdown-item">
                      <i
                        className="pi pi-envelope"
                        style={{ marginRight: "10px" }}
                      ></i>
                      {user.email}
                    </a>
                  </li>
                )}

                <li>
                  <a className="dropdown-item" onClick={confirm}>
                    <i
                      className="pi pi-sign-out"
                      style={{ marginRight: "10px" }}
                    ></i>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
